
const isProd = process.env.NODE_ENV === 'production';
const istest = process.env.NODE_ENV === 'test';
let API_HEADER = ''
let DataApiUrl = ''
let LOGIN_HEADER=''
let USER_HEADER=''
let PLAN_HEADER=''
let FLOW_HEADER=''
let IMG_HEADER= ''
let is_jwt=""
if (isProd) {
    // LOGIN_HEADER = 'http://sso.xyabcd.com/ssoauth';
    API_HEADER = taskConfig.API_HEADER || 'http://userc.xyabcd.com'
    DataApiUrl = taskConfig.DataApiUrl || "http://dataapi.xyabcd.com/datacenterservice"
    USER_HEADER= taskConfig.USER_HEADER || 'http://userc.xyabcd.com'
    PLAN_HEADER= taskConfig.PLAN_HEADER || 'http://planc.xyabcd.com'
    FLOW_HEADER= taskConfig.FLOW_HEADER || 'http://flowc.xyabcd.com'
    is_jwt=userConfig.is_jwt||"true"
    IMG_HEADER = projectConfig.IMG_HEADER || 'https://fileserver.xyabcd.com' //图片上传
} else if (istest) {
 
 
    API_HEADER= 'http://test.taskcenter.com'
    DataApiUrl = "http://192.168.0.226:30005/datacenterservice"
    USER_HEADER= 'http://test.usercenter.com'
    PLAN_HEADER= 'http://test.plancenter.com'
    FLOW_HEADER= 'http://test.flowcenter.com'
    IMG_HEADER = projectConfig.IMG_HEADER || 'https://fileserver.xyabcd.com' //图片上传
} else {

    API_HEADER=taskConfig.API_HEADER ||  "http://localhost:3003"
    DataApiUrl =taskConfig.DataApiUrl ||  "http://192.168.0.226:30005/datacenterservice"
    USER_HEADER= taskConfig.USER_HEADER || "http://test.usercenter.com"
    PLAN_HEADER=taskConfig.PLAN_HEADER ||  "http://localhost:3002"
    FLOW_HEADER=taskConfig.FLOW_HEADER ||  "http://localhost:3004"
    IMG_HEADER = projectConfig.IMG_HEADER || 'https://fileserver.xyabcd.com' //图片上传
}
export default {
    API_HEADER,
    DataApiUrl,
    LOGIN_HEADER,
    USER_HEADER,
    PLAN_HEADER,
    FLOW_HEADER,
    IMG_HEADER
}