/*
 * @Descripttion:
 * @version:
 * @Author: 邓旭阳
 * @Date: 2022-01-17 10:02:27
 * @LastEditors: 邓旭阳
 * @LastEditTime: 2022-02-21 11:11:57
 */
import Environment from "./Environment";
// const LOGIN_HEADER = '';
// const BigDataIframeSrc = '';
// const PAY_HEADER = '';

export default {
  // 用户接口
  getTreeData: Environment.USER_HEADER + "/department/query",
  getTableData: Environment.USER_HEADER + "/user/query",
  // 图片上传
  imageUpload: Environment.IMG_HEADER + "/presignedUrl?sysName=emercontrol",
  removeImageUpload: Environment.IMG_HEADER + "/removeUploadFile?sysName=emercontrol",

  // 预案接口
  UpdataPlanstatus: Environment.PLAN_HEADER + "/planstatus/update",

  // 任务接口
  getdetail: Environment.API_HEADER + "/task/querydetail",
  getTaskData: Environment.API_HEADER + "/task/query",
  addTaskData: Environment.API_HEADER + "/task/add",
  modifyTaskData: Environment.API_HEADER + "/task/modify",
  deleteTaskData: Environment.API_HEADER + "/task/delete",
  getTasktypeData: Environment.API_HEADER + "/tasktype/query",
  addTasktypeData: Environment.API_HEADER + "/tasktype/add",
  modifyTasktypeData: Environment.API_HEADER + "/tasktype/modify",
  deleteTasktypeData: Environment.API_HEADER + "/tasktype/delete",
  completeTask:Environment.API_HEADER+"/task/complete",
  activeTask:Environment.API_HEADER+"/task/active",

  // 工作流接口
  getWorkflowData: Environment.FLOW_HEADER + "/workflow/query",
  getworkflowNodeData: Environment.FLOW_HEADER + "/workflowNode/query",
};
