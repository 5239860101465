import axios from 'axios'

export default async function getToken() {
    var res = await axios.get(`${taskConfig.LOGIN_HEADER}/getJwt`);
    if (res.status === 200 && res.data.errcode == 0) {
        let time = new Date(new Date().getTime() + 1000 * (res.data.data.expires_in-30));
        var timestamp = Date.parse(time);
        localStorage.setItem("_t", res.data.data.token);
        localStorage.setItem("_tt", timestamp);
        return res.data.data.token
    }
}
