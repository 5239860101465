/*
 * @Descripttion: 封装一层axios
 */
import axios from "axios";
import getToken from "./getToken";

export default function request(config) {
  const instance = axios.create({
    timeout: 5000,
  });
  //请求拦截
  instance.interceptors.request.use(
    (config) => {
      if (taskConfig.is_jwt == "false") {
        return config;
      } else {
        let token = localStorage.getItem("_t");
        var dateString = localStorage.getItem("_tt");
        var timenow = new Date().getTime();
        if (!token || timenow > dateString) {
          return getToken().then((token) => {
            config.headers["Authorization"] = "Bearer " + token;
            return config;
          });
        } else {
          config.headers["Authorization"] = "Bearer " + token;
          return config;
        }
      }
    },
    (error) => {}
  );
  //响应拦截
  instance.interceptors.response.use(
    (res) => {
      return res.data;
    },
    (err) => {}
  );
  return instance(config);

  // .then(res => {
  //     resolve(res)
  // }).catch(err => {
  //     reject(err)
  // })
}
