<template>
  <div>
    <el-tabs
      type="border-card"
      v-loading="loading"
      element-loading-text="拼命加载中"
      v-model="activeName"
      @tab-remove="(name)=>$emit('closeTab',name)"
      @tab-click="(tab)=>$emit('handleTabClick', tab)"
      :before-leave="beforeHandle"
    >
      <el-tab-pane
        :closable="item.closable"
        v-for="(item, index) in tabitem"
        :key="index"
        :name="item.name"
        :label="item.label"
      >
        <slot :name="item.label"></slot>
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
export default {
  props: {
    tabitem: {
      type: Array,
      default: () => [
        {
          name: "init", //移除时使用
          label: "初始化",
        },
      ],
    },
    loading: {
      type: Boolean,
      default: false,
    },
    activename: {
      type: String,
      default: "",
    },
    jiankong: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {activeName:'init',jianKong:true};
  },
  watch: {
    activename(val){
      this.activeName=val
    },
    jiankong(val){
      this.jianKong = val
    },
  },
  methods:{
    beforeHandle(){
      if(this.jiankong == false){
        console.log('>>>>>>  !  <<<<<<');
        this.$message({
          showClose: true,
          message: '请保存',
          duration:1500
        })
        return false;
      }
    },
  }
};
</script>

<style lang="less">
/deep/.el-tabs--border-card > .el-tabs__content {
  padding: 0 !important;
}
.el-tabs--border-card {
  height: calc(100vh - 5px);
}
</style>