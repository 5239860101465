<template>
  <div class="user_container" v-if="tasktypeviewshow">
    <div class="left_box el-tabs--border-card">
      <div class="title"><i class="el-icon-s-fold"></i>任务类型</div>
      <TreeComp
        :tree-data="list"
        :show-edit="true"
        :treeLoading="treeLoading"
        :currentNodekeyNum="currentNodekey"
        @treeClick="treeClick"
        @handleAdd="handleadd"
        @empty="empty"
        @handleTabClicks="handleTabClicks"
        @removeTreeNode="removeTreeNode"
      ></TreeComp>
    </div>
    <div class="right_box">
      <MainTabBar :activename="activeName" :tabitem="tabItem" :jiankong="jiankong" @handleTabClick="handleTabClick">
        <!-- v-if="tableColumn.length" -->
        <div :slot="tabItem[0].label">
          <div class="fromContainer">
            <el-form
              :model="form"
              status-icon
              :rules="rules"
              ref="form"
              label-width="120px"
              size="mini"
            >
              <!-- left -->
              <el-row :gutter="20">
                <el-col :sm="24" :md="18" :lg="18" :xl="12" :offset="0">
                  <el-form-item label="任务类型名称：" prop="name">
                    <!-- 文本框 -->
                    <el-input v-model.trim="form.name"></el-input>
                  </el-form-item>
                  <!-- <el-form-item label="任务类型分类：">
                    <el-select
                      v-model="taskTypeName"
                      value-key
                      placeholder
                      clearable
                      filterable
                      disable
                    >
                      <el-option
                        v-for="item in options"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value"
                      ></el-option>
                      <el-option
                        :key="taskTypeName"
                        :label="taskTypeName"
                        :value="taskTypeName"
                      ></el-option>
                    </el-select>
                  </el-form-item> -->
                  <el-form-item label="任务类型备注：">
                    <el-input
                      type="textarea"
                      rows="8"
                      v-model="form.remark"
                    ></el-input>
                  </el-form-item>
                  <el-form-item label>
                    <el-button
                      type="primary"
                      class="el-icon-check"
                      size="mini"
                      @click="submitTasktype"
                      v-show="showDestination_id"
                      >保存</el-button
                    >
                  </el-form-item>
                </el-col>
              </el-row>
            </el-form>
          </div>
        </div>
        <div :slot="tabItem[1].label">
          <div class="process_container">
            <div class="right_box">
              <div class="fromContainer">
                <el-form
                  :model="form1[num]"
                  status-icon
                  :rules="rules1"
                  ref="form1"
                  label-width="100px"
                  size="mini"
                  style="width: 100%;margin-top: 15px;"
                >
                  <!-- 工作流 -->
                  <el-row :gutter="20">
                    <el-col :sm="24" :md="18" :lg="18" :xl="12" :offset="0">
                      <el-form-item label="工作流：">
                        <el-select
                          value-key
                          placeholder
                          clearable
                          filterable
                          v-model="form.flow_id"
                          @change="selectChanged"
                          @clear="empty"
                          >
                          <el-option
                          v-for="item in WorkflowList"
                            :key="item.row_id"
                            :label="item.name"
                            :value="item.row_id"
                          ></el-option>
                        </el-select>
                      </el-form-item>
                      <!-- <el-steps
                        :active="active"
                        finish-status="success"
                      space="50%"
                      >
                      <el-step
                      v-for="(item,index) in WorkflowNodeList"
                        :key="index"
                        :title="item.name"
                        @click.native="clickSubmit(index)"
                      ></el-step>
                      
                    </el-steps> -->
                      <el-form-item>
                        <div class="flow_box" v-if="chooseicon == 1 ? false : true">
                        <el-scrollbar style="height: 100%;">
                          <div style="width: 531px; height: 100px; display: flex;">
                            <ul class="flowNode">
                              <li
                                v-for="(item, index) in WorkflowNodeList"
                                :key="index"
                                >
                                <div class="li_div1" @click="clickSubmit(index)">
                                  <i :class="icon[index].i">{{
                                    icon[index].i == "icon_class" ? index + 1 : ""
                                  }}</i>
                                  <p :class="icon[index].p">{{ item.name }}</p>
                                </div>
                                <div
                                class="li_div2"
                                v-if="index !== WorkflowNodeList.length - 1"
                                @click="clickSubmit(index)"
                                ></div>
                              </li>
                            </ul>
                          </div>
                        </el-scrollbar>
                        </div>

                        <div class="flow_box" v-else>
                            <ul class="flowNode">
                              <li
                                v-for="(item, index) in WorkflowNodeList"
                                :key="index"
                                >
                                <div class="li_div1" @click="clickSubmit(index)">
                                  <i :class="icon[index].i">{{
                                    icon[index].i == "icon_class" ? index + 1 : ""
                                  }}</i>
                                  <p :class="icon[index].p">{{ item.name }}</p>
                                </div>
                                <div
                                class="li_div2"
                                v-if="index !== WorkflowNodeList.length - 1"
                                @click="clickSubmit(index)"
                                ></div>
                              </li>
                            </ul>
                        </div>
                      </el-form-item>

                        <el-form-item>
                        <el-radio-group v-model="executor_type" @change="radioChange">
                          <el-radio :label="0">个人</el-radio>
                          <el-radio :label="1">部门</el-radio>
                        </el-radio-group>
                      </el-form-item>
                      <el-form-item label="处理者：">
                        <el-cascader
                          v-model="executor_id"
                          :options="list2"
                          :props="{
                            value: 'row_id',
                            label: 'name',
                            checkStrictly: checkStrictly,
                          }"
                          @change="executorChange"
                          style="width: 100%"
                        ></el-cascader>
                      </el-form-item>
                      <el-form-item label>
                        <el-button
                          type="primary"
                          class="el-icon-check"
                          size="mini"
                          @click="submit"
                          v-show="showDestination_id"
                          >保存</el-button
                        >
                      </el-form-item>
                    </el-col>
                  </el-row>
                </el-form>
              </div>
            </div>
          </div>
        </div>
      </MainTabBar>
    </div>
  </div>
</template>
<script lang="js">
import axios from "axios";
import TreeComp from '../../components/treecomp/TreeComp';
import MainTabBar from '../../components/maintabbar/MainTabBar';
import request from '../../dataApi/_request';
import Api from '../../api/taskcApi';
export default {
  name: "taskType",
  components: {
    TreeComp,
    MainTabBar
  },
  props:{
    userdata:{
      type:Object,
      default: () => {}
    }
  },
  created() {
    // console.log(this.userdata,11111111);
  },
  data() {
    return {
          // userdata:{destination_id:61},
      list: [],
      lists:[],
      list2:[],
      tableColumn: [],
      searchKey: '',
      activeName: '',
      TaskType:'',
      tabItem: [
        {
          name: "init", //移除时使用
          label: "基础配置",
        },
        {
          name: "initProcess", //移除时使用
          label: "工作流配置",
        },
      ],
      form: {},
      form1:[{name:"",row_id:0,executor_type:0,executor:"",executor_id:""}],
      rules: { name:[
          { required: true, message: '请输入工作流名称', trigger: 'blur' },
        ],},
       rules1: {},
      options:[],

      radio:0,
      value:'',
      checkStrictly:true,
      taskTypeName:'',
      WorkflowList:'',
      WorkflowNodeList:'',
      num:0, 
      departmentList:[],
      userList:[], 
      currentNodekey:null,  
      treeLoading:true,
      executor_type:0,
      executor_id:null,
      WorkflowNodeListdata:1,
      wal:1,
      jiankong:true,
      forbid:1,
      chooseicon:1,
      reallyRow_id:null,
      showDestination_id:true,
      userInfo:[],
      tasktypeviewshow:true,
      tasktypeaddshow:true,
      tasktypemodifyshow:true,
      tasktypedelshow:true,
    };
  },
  mounted() {

    if (this.userdata.destination_id) {
      this.getTreeData()
      this.getWorkflowData()
      this.getDepartmentData()
      setTimeout(() => {
        this.getTreeDatas(this.lists[0].row_id)
      }, 500);
    } else {
      this.treeLoading = false
      this.showDestination_id = false
    }
  },
  created() {
    this.userInfo = this.$store.state.userInfo.data.right
    this.tasktypeChioce()
  },
  methods: {
    handleadd(){
      this.jiankong=true
      this.activeName = 'init'
    },
    // 权限功能
    tasktypeChioce(){
      if (this.userInfo) {
        if (this.userInfo.tasktypeview == false) {
          this.$message("暂无查看权限")
          this.tasktypeviewshow = false
        }

        if (this.userInfo.tasktypeadd == false) {
          this.tasktypeaddshow = false
        }

        if (this.userInfo.tasktypemodify == false) {
          this.tasktypemodifyshow = false
        }
        
        if (this.userInfo.tasktypedel == false) {
          this.tasktypedelshow = false
        }
      }
    },

    handleTabClick(tab) {
     this.activeName = tab.name
    },
    handleTabClicks(){
      if (this.activeName == 'initProcess') {
        this.activeName = 'init'
        this.jiankong = true
        setTimeout(() => {
          this.jiankong = false
        }, 200);
      } else {
        this.activeName = 'init'
        this.jiankong = true
        setTimeout(() => {
          this.jiankong = false
        }, 200);
      }
    },
    treeList(arr){
          let parents = arr.filter(value => value.parent_id == 'undefined' || value.parent_id == null || value.parent_id == 0)
                let children = arr.filter(value => value.parent_id !== 'undefined' && value.parent_id != null)
                let translator = (parents, children) => {
                    parents.forEach((parent) => {
                        children.forEach((current, index) => {
                            if (current.parent_id === parent.row_id) {
                                let temp = JSON.parse(JSON.stringify(children))
                                temp.splice(index, 1)
                                translator([current], temp)
                                typeof parent.children !== 'undefined' ? parent.children.push(current) : parent.children = [current]
                            }
                        }
                        )
                    }
                    )
                }
                translator(parents, children)
                return parents
    },
    getTreeData(currentNodekey) {
      let params = {
        method: 'get',
        url: Api.getTasktypeData,
        params:{param:{order_by:"row_id asc"},pageNum:999,destination_id:this.userdata.destination_id}
      }       
      request(params).then((result) => {
        let children = this.treeList(result.data.list)
        this.lists = result.data.list
        this.list =JSON.parse(JSON.stringify([{row_id:-1,name:'任务类型',children:children}]))//进行树状结构处理的数据
        this.treeLoading = false
        if(!currentNodekey){
          this.childrenFirst(this.lists)
          this.TabWorkflowNodeData(this.lists[0].flow_id)
        }else if(currentNodekey == '添加'){
          if(result.data.list[result.data.list.length-1].flow_config != ""){
            this.form1 = JSON.parse(result.data.list[result.data.list.length-1].flow_config)
            
          }else{
            this.form1 = [{name:"",row_id:0,executor_type:0,executor:"",executor_id:""}]
          }
          this.currentNodekey=result.data.list[result.data.list.length-1].row_id
          this.form = result.data.list[result.data.list.length-1]
          this.treeClick(result.data.list[result.data.list.length - 1])
        }
      }).catch((err) => {
      });
    },
    childrenFirst(list){
        if(list[0].children){
          this.childrenFirst(list[0].children)
        }else{  
          if (this.reallyRow_id) {
            for (let i = 0; i < list.length; i++) {
              if (this.reallyRow_id == list[i].row_id) {
                this.currentNodekey = list[i].row_id
              }
            }
          } else {
            this.treeClick(list[0])
            this.form = list[0]
            this.currentNodekey = list[0].row_id
          }
        }
      },
    treeClick(data) {
      console.log(data,'data');
      // this.empty()
      // this.getDepartmentData()
      this.jiankong = true
      if (data.row_id != -1) {
        this.reallyRow_id = data.row_id
        if (data.flow_id == "") {
          this.getTreeData()
          this.getWorkflowData()
          // this.getDepartmentData()
        } else {
          this.wal = 2
          this.selectChanged(data.flow_id,this.wal)
          this.form = data
          this.form1 = this.form
          if(data.flow_config == "" || data.flow_config == undefined){
            this.form1 = [{name:"",row_id:0,executor_type:0,executor:"",executor_id:""}]
            this.executor_id = ""
            this.WorkflowNodeList = []
          }else{
            this.getDepartmentData()
            this.form1 = JSON.parse(data.flow_config)
            this.TaskType = data.flow_id
            this.executor_type = this.form1[this.num].executor_type
            // console.log(this.form1,this.num,this.form,this.WorkflowList);
            this.executor_id = this.form1[this.num].executor_id
            for (let i = 0; i < this.WorkflowList.length; i++) {
              if (this.WorkflowList[i].name == this.form.name) {
                this.form.flow_id = this.WorkflowList[i].row_id
              }
            }
            // this.name = this.form1[this.num].name
            // console.log(this.executor_id,this.executor_type,this.form1,'222');
            // this.getDepartmentData()
            this.TabWorkflowNodeData(data.flow_id)
          }
          this.lists.forEach(item => {
            if(item.row_id == this.form.parent_id){
              this.taskTypeName = item.name
            }
          })
        }
        this.getTreeDatas(data.row_id)
      } else {
        this.form = {}
      }
    },
    getTreeDatas(id) {
      let params = {
        method: 'get',
        url: Api.getTasktypeData,
        params:{param:{order_by:"row_id asc"},pageNum:999,destination_id:this.userdata.destination_id}
      }       
      request(params).then((result) => {
        for (let i = 0; i < result.data.list.length; i++) {
          if (id == result.data.list[i].row_id) {
            this.list[0].children[i] = result.data.list[i]
            this.form = result.data.list[i]
          }
        }
        this.treeLoading = false
      }).catch((err) => {
      });
    },
    TabWorkflowNodeData(val){
      if (val) {
        let params={
          method:'get',
          url:Api.getworkflowNodeData,
          params:{param:{flow_id:val,searchmodel:"exact"},pageNum:999,destination_id:this.userdata.destination_id}
        }
        request(params).then((result) => {
        this.WorkflowNodeList = result.data.list
          this.icon = []
          result.data.list.forEach( (item) => {
        
            this.icon.push({i:'',p:''})
          });
          this.clickSubmit(0)
        }).catch((err) => {
        });
      }
    },
    submitTasktype(){
      this.$refs.form.validate((valid) => {
        if (valid) {
          this.isEdit = !!this.form.row_id
           if (this.isEdit) {
        this.modifyTasktype()
      } else {
        this.addTasktype()
        this.form = {}
      }
        } else {  
          return false;
        }
    })
     
    },
    addTasktype(){
      const { name, remark,flow_id,parent_id,flow_config} = this.form
      let params = {
        method: 'get',
        url: Api.addTasktypeData,
        params: { data: { name, remark,flow_id,parent_id,flow_config},destination_id:this.userdata.destination_id }
      }
      request(params).then((result) => {
        this.treeLoading = true
        if(result.errcode == 0){
          this.jiankong = true
          this.$message({
            message: '添加成功,请配置工作流',
            type: 'success',
            duration:1000
          });

        }else{
          this.$message.error('添加失败');
        }
        this.getTreeData('添加')
      }).catch((err) => {
      });
    },
    modifyTasktype(){
      if (this.userInfo && this.tasktypemodifyshow == false) {
        this.$message('暂无修改权限')
      } else {
        const { name, remark,flow_id,parent_id,row_id,flow_config} = this.form
        let params = {
          method: 'get',
          url: Api.modifyTasktypeData,
          params: {row_id:row_id,data: { name, remark,flow_id,parent_id,flow_config} }
        }
        request(params).then((result) => {
          if(result.errcode == 0){
            this.$message({
                  message: '修改成功',
                  type: 'success',
              duration:1000
            });
            this.currentNodekey = 0
            this.getTreeData()
          }else{
            this.$message.error('修改失败');
          }
        }).catch((err) => {
        });
      }
    },
    removeTreeNode(row_id){
      let params = {
        method: 'get',
        url: Api.deleteTasktypeData,
        params: {row_id:row_id }
      }
      request(params).then((result) => {
        this.treeLoading = true
       if(result.errcode == 0){
          this.$message({
                message: '删除成功',
                type: 'success',
            duration:1000
          });
          this.reallyRow_id = null
          this.getTreeData()
        }else{
          this.$message.error('删除失败');
        }
      }).catch((err) => {
      });
    },
    getDepartmentData(){
      // setTimeout(() => {
        
      let params = {
        method: 'get',
        url: Api.getTreeData,
        params:{pageNum:999,destination_id:this.userdata.destination_id}
      }
      request(params).then((result) => {
        this.departmentList = result.data.list
        // console.log(this.form1,this.num);
       if(this.form1[this.num].executor_type == 0){
         this.checkStrictly = false
         this.list2 = result.data.list
         this.getUserData()
       }else if(this.form1[this.num].executor_type == 1){
          this.checkStrictly = true
          this.list2 = this.treeList(result.data.list)
       }
      }).catch((err) => {
      });
    // }, 250);
    },
    getUserData(){
      let params={
        method:'get',
        url:Api.getTableData,
        params:{pageNum:999,destination_id:this.userdata.destination_id}
      }
      request(params).then((result) => {
        this.userList = result.data.list
        this.list2.forEach((item) => {
          item.children=[]
          this.userList.forEach((item1) => {
            if(item.row_id == item1.department_id){
              item.children.push(item1)
            }
          })
          // 如果该部门下的人员空的时候，不可选状态
          if(!item.children){
            item.disabled = true
          }
        })
        // 处理数据，把数据处理成树状结构
        this.list2 = this.treeList(this.list2)
      }).catch((err) => {
      });
    },
    // 工作流
    clickSubmit(num){
      if (this.WorkflowNodeListdata == 1) {
        this.num = num
        this.executor_type = this.form1[num].executor_type
        this.executor_id = this.form1[num].executor_id
        for(let i=0;i<this.icon.length;i++){
          this.icon[i].i = 'icon_class'
          this.icon[i].p = ''
        }
        this.icon[num].i = 'el-icon-circle-check'
        this.icon[num].p = 'p_icon'
        // this.form1[this.num].executor_type = this.form1[num].executor_type
        if (this.icon.length == 1) {
          this.chooseicon = 1
        } else if (this.icon.length > 1){
          this.chooseicon = 2
        }
      } else {
        this.WorkflowNodeList = ''
      }
      this.wal = 1
    },
    empty(){
      this.forbid = 2
      this.WorkflowNodeList = ''
      this.executor_id = ''
      this.executor_type = ''
      setTimeout(() => {
        this.forbid = 1
      }, 500);
    },
    selectChanged(val,wal){
      setTimeout(() => {
        if (this.forbid == 2) {
          
        } else {
          if (!wal) {
            if (this.wal == 1) {
              this.WorkflowNodeListdata = 1
              this.getworkflowNodeData(val)
            } else if (this.wal == 2){
              this.WorkflowNodeListdata = 1
              this.getworkflowNodeDatas(val)
            }
          } else {
            if (wal == 1) {
              this.WorkflowNodeListdata = 1
              this.getworkflowNodeData(val)
            } else if (wal == 2){
              this.WorkflowNodeListdata = 1
              this.getworkflowNodeDatas(val)
            }
          }
        }
      }, 100);
    },
    radioChange(value){
      if (this.userdata.destination_id) {
        this.form1[this.num].executor_type = value
        this.getDepartmentData()
      }
    },
    getWorkflowData(){
      let params={
        method:'get',
        url:Api.getWorkflowData,
        params:{pageNum:999,destination_id:this.userdata.destination_id}
      }
      request(params).then((result) => {
        this.WorkflowList = result.data.list
        
      }).catch((err) => {
      });
    },
    getworkflowNodeDatas(val){
      if (val) {
        let params={
          method:'get',
          url:Api.getworkflowNodeData,
          params:{param:{flow_id:val,searchmodel:"exact"},pageNum:999,destination_id:this.userdata.destination_id}
        }
        request(params).then((result) => {
          this.WorkflowNodeList = result.data.list
          this.icon = []
          result.data.list.forEach( (item) => {
            this.icon.push({i:'',p:''})
          });
          this.clickSubmit(0)
        }).catch((err) => {
        });
      }
    },
    getworkflowNodeData(val){
      if (val) {
        let params={
          method:'get',
          url:Api.getworkflowNodeData,
          params:{param:{flow_id:val,searchmodel:"exact"},pageNum:999,destination_id:this.userdata.destination_id}
        }
        request(params).then((result) => {
          this.WorkflowNodeList = result.data.list
          this.form1=[]
          this.icon = []
          result.data.list.forEach( (item) => {
            let arr =  {name:item.name,row_id:item.row_id,executor_type:0,executor:"",executor_id:""}
            this.form1.push(arr)
            this.icon.push({i:'',p:''})
          });
          this.clickSubmit(0)
          
        }).catch((err) => {
        });
      }
    },
    executorChange(val){
      this.form1[this.num].executor_id = val[val.length-1]
      if(this.form1[this.num].executor_type == 1){
        this.departmentList.forEach(item => {
          if(item.row_id == val[val.length-1]){
            this.form1[this.num].executor = item.name
          }
        })
      }else{
        this.userList.forEach(item => {
          if(item.row_id == val[val.length-1]){
            this.form1[this.num].executor = item.name
          }
        })
      }
    },
    submit(){

      this.form.flow_config = JSON.stringify(this.form1)
      this. modifyTasktype()
    },
    
  },
};
</script>
<style lang="less" scoped>
@baseColor: #05adaa;
.user_container {
  .el-select,
  .el-date-editor {
    width: 100%;
  }
  display: flex;

  .left_box {
    width: 200px;
    height: calc(100vh - 5px);
    .title {
      text-align: left;
      font-size: 14px;
      color: @baseColor;
      padding-left: 5px;
      height: 40px;
      line-height: 40px;
    }
  }
  .right_box {
    flex: 1;
    overflow: hidden;
    .fromContainer {
      padding-left: 0px !important;
      padding-top: 15px;
      height: calc(100vh - 100px);
      padding-top: 10px;
      overflow-y: auto;
      overflow-x: hidden;
    }
  }
}
.tab_style {
  height: 99.9vh;
}
.listyle {
  background-color: antiquewhite;
}
.flow_box {
  border: 1px solid #e1e1e1;
  height: 100px;
  display: flex;
}
.icon_class {
  border-radius: 225px;
  border: 2px solid #000;
  display: inline-block;
      width: 40px;
    height: 41px;
  font-size: 25px;
  padding-top: 6px;
  padding-left: 10px;
}
.p_icon {
  color: #70c546;
  margin-top: 10px;
  margin-top: 2px !important;
}
.flowNode {
  display: flex;
  width: auto;
  margin: auto;
  padding: 0;
  li {
    list-style: none;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    .li_div1 {
      width: 38px;
      // .el-icon-circle-check {
      //   font-size: 40px !important;
      //   margin-top: -3px !important;
      //   margin-left: -1px !important;
      //   color: #70c546 !important;
      // }
      .el-icon-circle-check {
        font-size: 46px !important;
        margin-top: -3px !important;
        margin-left: -5px !important;
        color: #70c546 !important;
      }
      p {
        font-size: 13px;
        text-align: center;
        margin-top: 7px;
        line-height: 1.5;
      }
    }
    .li_div2 {
      width: 50px;
      border-bottom: 2px solid;
      float: right;
      top: 25px;
      margin-top: 19px;
    }
  }
}
/deep/.el-scrollbar {
  overflow: hidden; 
  height: 100px !important;
  height: 10vh !important;
}
.el-scrollbar__wrap .el-scrollbar__view{
    white-space: nowrap; overflow: scroll; overflow-y: hidden;
    overflow-x: hidden;}
.el-scrollbar__thumb{
  height: 0;
}
/deep/ .el-form-item__label{
  font-size: 12px;
}
/deep/ .el-radio__label{
  font-size: 12px;
}
/deep/.el-scrollbar__wrap {
  overflow-x: hidden;
  overflow-y: auto;
}
/deep/.el-scrollbar {
  height: 10vh !important;
}
// /deep/.el-tabs__content{
//   padding:15px 0px 15px 15px !important;
// }
</style>
